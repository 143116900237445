.popup {
  width: 100vw;
  max-width: 30em;
  background: white;
  bottom: 20px;
  padding-top: 2em;
  padding-bottom: 2em;
  left: -780px;
  position: fixed;
  border-radius: 15px;
  box-shadow: 2px 2px 20px 15px rgba(0, 0, 0, 0.03);
  transition: 0.5s;
  z-index: 999;
}

.ns-close {
  color: rgb(233, 107, 74) !important;
  transition: 0.5s;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 8px;
  top: 4px;
  overflow: hidden;
  text-indent: 100%;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.ns-close:hover,
.ns-close:focus {
  transition: 0.5s;
  outline: none;
}

.ns-close::before,
.ns-close::after {
  transition: 0.5s;
  content: "";
  position: absolute;
  width: 4px;
  height: 80%;
  top: 58%;
  left: 58%;
  background: rgb(233, 107, 74);
}

.ns-close:hover::before,
.ns-close:hover::after {
  transition: 0.5s;
  background-color: rgb(190, 48, 12) !important;
}

.ns-close::before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.ns-close::after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.arrow {
  position: fixed;
  bottom: 50px;
  left: 50%;
  cursor: pointer;
  margin-left: -20px;
  width: 20px;
  height: 20px;
  padding: 20px;
  z-index: 99;
  background-color: var(--clr4);
  border-radius: 50em;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yOTMuNzUxLDQ1NS44NjhjLTIwLjE4MSwyMC4xNzktNTMuMTY1LDE5LjkxMy03My42NzMtMC41OTVsMCwwYy0yMC41MDgtMjAuNTA4LTIwLjc3My01My40OTMtMC41OTQtNzMuNjcyICBsMTg5Ljk5OS0xOTBjMjAuMTc4LTIwLjE3OCw1My4xNjQtMTkuOTEzLDczLjY3MiwwLjU5NWwwLDBjMjAuNTA4LDIwLjUwOSwyMC43NzIsNTMuNDkyLDAuNTk1LDczLjY3MUwyOTMuNzUxLDQ1NS44Njh6Ii8+DQo8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjIwLjI0OSw0NTUuODY4YzIwLjE4LDIwLjE3OSw1My4xNjQsMTkuOTEzLDczLjY3Mi0wLjU5NWwwLDBjMjAuNTA5LTIwLjUwOCwyMC43NzQtNTMuNDkzLDAuNTk2LTczLjY3MiAgbC0xOTAtMTkwYy0yMC4xNzgtMjAuMTc4LTUzLjE2NC0xOS45MTMtNzMuNjcxLDAuNTk1bDAsMGMtMjAuNTA4LDIwLjUwOS0yMC43NzIsNTMuNDkyLTAuNTk1LDczLjY3MUwyMjAuMjQ5LDQ1NS44Njh6Ii8+DQo8L3N2Zz4=);
  background-size: 20px 15px;
  background-repeat: no-repeat;
  background-position: center;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@media (max-width: 728px) {
  section.cards .card {
    width: 100%;
    display: block;
    margin: 10px 0 0 0 !important;
    padding: 0;
  }

  .popup {
    width: 280px;
  }
}

.ant-btn-primary.active,
.ant-btn-primary:active {
  border-radius: 0.7em !important;
  display: none;
  box-shadow: none !important;
  background-color: rgb(80, 80, 80);
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#news-signup {
  position: fixed;
  width: 100%;
  visibility: hidden;
  z-index: 10002;

  opacity: 0;
  -webkit-transform: scale(0.5);

  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

#news-signup .wrapper {
  /*background: #252b33;*/
  background: #948e99;
  background: -webkit-linear-gradient(to left, #252b33, #2e1437);
  background: linear-gradient(to left, #252b33, #2e1437);
  position: relative;
  margin: 0 auto;
  text-align: center;
  -moz-border-radius: 3px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.5);
  width: 98%;
}

#news-signup_bg {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.4;
  z-index: 10001;
}

.contact-row {
  display: inline-flex;
}

#news-signup_close {
  position: absolute;
  left: 90%;
  top: 4%;

  width: 30px;
  height: 30px;
  color: #e96b4a;

  font-weight: bold;

  text-align: center;
  border-radius: 5em;
  background-color: rgba(255, 255, 255, 0.699);
  cursor: pointer;
  text-decoration: none;
}

#news-signup_close::before {
  content: "×";
  font: 400 26px/1em "Roboto Slab", serif;
}

#news-signup_close:hover {
  opacity: 0.8;
}

#news-signup .newsletter-content {
  padding: 100px 3px;
  margin: 0 auto;
}

#news-signup .newsletter-content h2 {
  font: 300 24px/1em "Roboto Slab", serif;
  color: #fff;
  text-align: center;
  margin: 0 auto 15px;
}

#news-signup .newsletter-content p {
  margin: 0 auto 25px;
  font: 300 16px/1em "Roboto Slab", serif;
  color: #7e8890;
}

#news-signup .newsletter-content form {
  margin: 0;
  padding: 0;
  width: 90%;
}

#news-signup .newsletter-content form p {
  margin: 0 auto 10px;
}

#news-signup .newsletter-content form p:not(.button) {
  float: none;
  width: 100%;
}

#news-signup .newsletter-content form p:not(.button) input {
  width: 100%;
  margin: 0;
  padding: 12px 15px !important;
  border-color: #d0d5d8;
}

#news-signup .newsletter-content form .button {
  float: none;
  width: 100%;
}

#news-signup .newsletter-content form .button input {
  width: 100%;
  padding: 20px 25px 18px !important;
  font-size: 12px;
}

#news-signup .newsletter-content p.footnote {
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.8;
  font: 100 11px "Roboto Slab", serif;
  color: #abb0b7;
  margin-bottom: 0;
}

@media screen and (min-width: 800px) {
  #news-signup .wrapper {
    width: 800px;
    margin: 7% auto;
  }

  #news-signup .newsletter-content form .button input {
    width: auto;
    margin: 0;
  }

  #news-signup .newsletter-content form .button {
    float: left;
    width: auto;
    margin-left: 15px;
  }

  #news-signup .newsletter-content form {
    width: auto;
  }

  #news-signup .newsletter-content form p {
    margin: 0 auto 15px;
  }

  #news-signup .newsletter-content form p:not(.button) {
    float: left;
    width: auto;
  }

  .newsletter-content form p:not(.button) input {
    width: 300px !important;
    margin: 0 15px 0 0;
  }
}

.react-responsive-modal-overlay {
  z-index: 999999999999999999 !important;
}

.chatbot {
  display: none;
}

.hyperlink-hover:hover {
  color: #d8d8d8 !important;
  text-decoration: underline;
}

.ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 11px;
  margin-left: 5px;
  margin-top: 6px;
  line-height: 30px;
}

.container {
  max-width: 640px;
  margin: 0 auto;
}

.video-selected {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
}

.video-iframe {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  margin-bottom: 10px;
}

.video-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-thumbnails {
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.video-thumb {
  min-height: 200px;
  height: auto;
}

.video-thumb img {
  margin-top: 20px;
  border-radius: 2em;
  object-fit: cover;
  background-color: #d8d9da;
  width: 100%;
  height: 200px;
}

.video-thumb iframe {
  display: none;
}

.video-thumb,
.video-selected {
  padding: 5px;
}

.video-thumb p {
  margin: 0;
  margin-left: 5px;

  font-size: 12px;
}

.video-thumb p.name {
  margin-top: 5px;
  margin-left: 5px;
  font-weight: bold;
}

.active {
  box-shadow: 0 0 2px #898989;
}

body .appBuilder-carousel {
  margin-bottom: 64px;
  margin-top: 56px;
}

body .appBuilder-carousel .carousel .carousel-indicators {
  width: 76%;
}

body .appBuilder-carousel .carousel .carousel-indicators li {
  border: none;
  background-color: #98acb9;
  height: 18px;
  margin-right: 28px;
  width: 18px;
}

body .appBuilder-carousel .carousel .carousel-indicators li.active {
  background-color: #50e3c2;
}

body .appBuilder-carousel .carousel .carousel-inner .item h3 {
  font-weight: 700;
  font-size: 25px;
  color: #ffffff;
  letter-spacing: 0.89px;
  line-height: 38.41px;
  margin-bottom: 50px;
  margin-top: 30px;
  position: relative;
}

.carousel .control-dots {
  visibility: hidden;
}

.carousel * {
  max-width: 70em !important;
  max-height: 40em !important;
  box-sizing: border-box;
}

.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0) !important;
  color: rgba(255, 255, 255, 0) !important;
}

.carousel.carousel-slider .control-arrow {
  top: 22% !important;
  color: #fff;
  background-color: rgba(197, 108, 7, 0.644) !important;
  border-radius: 10em;
  height: 2.5em;
  width: 2.5em;
  font-size: 20px !important;
  bottom: 0 !important;
  margin-top: 0;
  padding: 5px !important;
}

.carousel .control-prev.control-arrow {
  left: 0.5em !important;
}

.carousel .control-next.control-arrow {
  right: 0.5em !important;
}

.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  background: rgba(0, 0, 0, 0) !important;
}

body .appBuilder-carousel .carousel .carousel-inner .item h3::after {
  background-color: #ffffff;
  bottom: -18px;
  content: "";
  height: 6px;
  left: 0;
  position: absolute;
  width: 158px;
}

body .appBuilder-carousel .carousel .carousel-inner .item h4 {
  font-weight: 700;
  font-size: 25px;
  color: #43eec5;
  letter-spacing: 1.02px;
  line-height: 33px;
}

.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: rgba(255, 255, 255, 0) !important;
  border: 2px solid #ff636b !important;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #ff797f !important;
  font-weight: 500 !important;
  padding-left: 1em;
}

@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

.home-image-mobile-home {
  display: none;
}

.container1 {
  top: 6em;
  right: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
  height: 100vh;
  max-height: 60em;
  display: block;
  overflow: hidden;
}

.container1 .inner-container1 {
  position: absolute;
  left: 0;
  min-width: 50em;
  width: 60%;
  margin: 0 auto;
}

.shape1 {
  margin: 0 auto;
  position: relative;
  opacity: 0.4;
}

.container {
  top: 0;
  right: 0;
  position: absolute;
  width: 100%;

  height: 100vh;
  display: block;
  overflow: hidden;
}

.container .inner-container {
  position: absolute;
  right: 0;
  min-width: 50em;
  width: 60%;
  margin: 0 auto;
}

.shape {
  margin: 0 auto;
  position: relative;
  opacity: 0.4;
}

.random-shape:after {
  font-family: FontAwesome;
  content: "\f04d";
}

@keyframes shape-1 {
  0% {
    transform: translate3d(0, 0, 0) rotate(155deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(515deg);
  }
}

.shape-container--1 {
  animation: shape-1 56s linear infinite;
}

.shape-container--1 .random-shape:after {
  margin: 4.7rem;
  color: #e96b4a;
  display: none;
  font-size: 0.94rem;
  content: "";
}

@keyframes shape-2 {
  0% {
    transform: translate3d(0, 0, 0) rotate(354deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(714deg);
  }
}

.shape-container--2 {
  animation: shape-2 60s linear infinite;
}

.shape-container--2 .random-shape:after {
  margin: 6.7rem;
  color: #e96b4a;
  font-size: 1.34rem;
  display: none;
  content: "";
}

@keyframes shape-3 {
  0% {
    transform: translate3d(0, 0, 0) rotate(151deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(511deg);
  }
}

.shape-container--3 {
  animation: shape-3 54s linear infinite;
}

.shape-container--3 .random-shape:after {
  margin: 2.7rem;
  color: #e96b4a;
  display: none;
  font-size: 0.54rem;
  content: "";
}

@keyframes shape-4 {
  0% {
    transform: translate3d(0, 0, 0) rotate(74deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(434deg);
  }
}

.shape-container--4 {
  animation: shape-4 66s linear infinite;
}

.shape-container--4 .random-shape:after {
  margin: 7.7rem;
  color: #e96b4a;
  display: none;
  font-size: 1.54rem;
  content: "";
}

@keyframes shape-5 {
  0% {
    transform: translate3d(0, 0, 0) rotate(50deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(410deg);
  }
}

.shape-container--5 {
  animation: shape-5 47s linear infinite;
}

.shape-container--5 .random-shape:after {
  margin: 8.7rem;
  display: none;
  color: #e96b4a;
  font-size: 1.74rem;
  content: "";
}

@keyframes shape-6 {
  0% {
    transform: translate3d(0, 0, 0) rotate(277deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(637deg);
  }
}

.shape-container--6 {
  animation: shape-6 63s linear infinite;
}

.shape-container--6 .random-shape:after {
  margin: 4.7rem;
  color: #e96b4a;
  font-size: 0.94rem;
  display: none;
  content: "";
}

@keyframes shape-7 {
  0% {
    transform: translate3d(0, 0, 0) rotate(305deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(665deg);
  }
}

.shape-container--7 {
  animation: shape-7 44s linear infinite;
}

.shape-container--7 .random-shape:after {
  margin: 4.7rem;
  color: #e96b4a;
  display: none;
  font-size: 0.94rem;
  content: "";
}

@keyframes shape-8 {
  0% {
    transform: translate3d(0, 0, 0) rotate(39deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(399deg);
  }
}

.shape-container--8 {
  animation: shape-8 46s linear infinite;
}

.shape-container--8 .random-shape:after {
  margin: 7.7rem;
  color: #e96b4a;
  font-size: 1.54rem;
  content: "";
  display: none;
}

@keyframes shape-9 {
  0% {
    transform: translate3d(0, 0, 0) rotate(359deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(719deg);
  }
}

.shape-container--9 {
  animation: shape-9 60s linear infinite;
}

.shape-container--9 .random-shape:after {
  margin: 9.7rem;
  color: #e96b4a;
  display: none;
  font-size: 1.94rem;
  content: "";
}

@keyframes shape-10 {
  0% {
    transform: translate3d(0, 0, 0) rotate(50deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(410deg);
  }
}

.shape-container--10 {
  animation: shape-10 42s linear infinite;
}

.shape-container--10 .random-shape:after {
  margin: 8.7rem;
  color: #e96b4a;
  display: none;
  font-size: 1.74rem;
  content: "";
}

@keyframes shape-11 {
  0% {
    transform: translate3d(0, 0, 0) rotate(119deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(479deg);
  }
}

.shape-container--11 {
  animation: shape-11 61s linear infinite;
}

.shape-container--11 .random-shape:after {
  margin: 5.7rem;
  display: none;
  color: #e96b4a;
  font-size: 1.14rem;
  content: "";
}

@keyframes shape-12 {
  0% {
    transform: translate3d(0, 0, 0) rotate(187deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(547deg);
  }
}

.shape-container--12 {
  animation: shape-12 52s linear infinite;
}

.shape-container--12 .random-shape:after {
  margin: 1.7rem;
  color: #e96b4a;
  font-size: 0.34rem;
  content: "";
}

@keyframes shape-13 {
  0% {
    transform: translate3d(0, 0, 0) rotate(85deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(445deg);
  }
}

.shape-container--13 {
  animation: shape-13 45s linear infinite;
}

.shape-container--13 .random-shape:after {
  margin: 9.7rem;
  color: #e96b4a;
  font-size: 1.94rem;
  content: "";
}

@keyframes shape-14 {
  0% {
    transform: translate3d(0, 0, 0) rotate(258deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(618deg);
  }
}

.shape-container--14 {
  animation: shape-14 50s linear infinite;
}

.shape-container--14 .random-shape:after {
  margin: 7.7rem;
  color: #e96b4a;
  font-size: 1.54rem;
  content: "";
}

@keyframes shape-15 {
  0% {
    transform: translate3d(0, 0, 0) rotate(3deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(363deg);
  }
}

.shape-container--15 {
  animation: shape-15 50s linear infinite;
}

.shape-container--15 .random-shape:after {
  margin: 4.7rem;
  color: #e96b4a;
  font-size: 0.94rem;
  content: "";
}

@keyframes shape-16 {
  0% {
    transform: translate3d(0, 0, 0) rotate(88deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(448deg);
  }
}

.shape-container--16 {
  animation: shape-16 66s linear infinite;
}

.shape-container--16 .random-shape:after {
  margin: 6.7rem;
  color: #e96b4a;
  font-size: 1.34rem;
  content: "";
}

@keyframes shape-17 {
  0% {
    transform: translate3d(0, 0, 0) rotate(38deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(398deg);
  }
}

.shape-container--17 {
  animation: shape-17 61s linear infinite;
}

.shape-container--17 .random-shape:after {
  margin: 10.7rem;
  color: #e96b4a;
  font-size: 2.14rem;
  content: "";
}

@keyframes shape-18 {
  0% {
    transform: translate3d(0, 0, 0) rotate(83deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(443deg);
  }
}

.shape-container--18 {
  animation: shape-18 65s linear infinite;
}

.shape-container--18 .random-shape:after {
  margin: 10.7rem;
  color: #e96b4a;
  font-size: 2.14rem;
  content: "";
}

@keyframes shape-19 {
  0% {
    transform: translate3d(0, 0, 0) rotate(108deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(468deg);
  }
}

.shape-container--19 {
  animation: shape-19 63s linear infinite;
}

.shape-container--19 .random-shape:after {
  margin: 3.7rem;
  color: #e96b4a;
  font-size: 0.74rem;
  content: "";
}

@keyframes shape-20 {
  0% {
    transform: translate3d(0, 0, 0) rotate(155deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(515deg);
  }
}

.shape-container--20 {
  animation: shape-20 59s linear infinite;
}

.shape-container--20 .random-shape:after {
  margin: 7.7rem;
  color: #e96b4a;
  font-size: 1.54rem;
  content: "";
}

@keyframes shape-21 {
  0% {
    transform: translate3d(0, 0, 0) rotate(120deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(480deg);
  }
}

.shape-container--21 {
  animation: shape-21 62s linear infinite;
}

.shape-container--21 .random-shape:after {
  margin: 9.7rem;
  color: #e96b4a;
  font-size: 1.94rem;
  content: "";
}

@keyframes shape-22 {
  0% {
    transform: translate3d(0, 0, 0) rotate(236deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(596deg);
  }
}

.shape-container--22 {
  animation: shape-22 68s linear infinite;
}

.shape-container--22 .random-shape:after {
  margin: 8.7rem;
  color: #e96b4a;
  font-size: 1.74rem;
  content: "";
}

@keyframes shape-23 {
  0% {
    transform: translate3d(0, 0, 0) rotate(170deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(530deg);
  }
}

.shape-container--23 {
  animation: shape-23 48s linear infinite;
}

.shape-container--23 .random-shape:after {
  margin: 10.7rem;
  color: #e96b4a;
  font-size: 2.14rem;
  content: "";
}

@keyframes shape-24 {
  0% {
    transform: translate3d(0, 0, 0) rotate(240deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(600deg);
  }
}

.shape-container--24 {
  animation: shape-24 41s linear infinite;
}

.shape-container--24 .random-shape:after {
  margin: 10.7rem;
  display: none;
  color: #e96b4a;
  font-size: 2.14rem;
  content: "";
}

@keyframes shape-25 {
  0% {
    transform: translate3d(0, 0, 0) rotate(134deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(494deg);
  }
}

.shape-container--25 {
  animation: shape-25 43s linear infinite;
}

.shape-container--25 .random-shape:after {
  margin: 9.7rem;
  color: #e96b4a;
  font-size: 1.94rem;
  content: "";
  display: none;
}

@keyframes shape-26 {
  0% {
    transform: translate3d(0, 0, 0) rotate(314deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(674deg);
  }
}

.shape-container--26 {
  animation: shape-26 64s linear infinite;
}

.shape-container--26 .random-shape:after {
  margin: 6.7rem;
  color: #ff94a1;
  font-size: 1.34rem;
  content: "";
}

@keyframes shape-27 {
  0% {
    transform: translate3d(0, 0, 0) rotate(5deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(365deg);
  }
}

.shape-container--27 {
  animation: shape-27 69s linear infinite;
}

.shape-container--27 .random-shape:after {
  margin: 4.7rem;
  color: #ff94a1;
  font-size: 0.94rem;
  content: "";
}

@keyframes shape-28 {
  0% {
    transform: translate3d(0, 0, 0) rotate(158deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(518deg);
  }
}

.shape-container--28 {
  animation: shape-28 63s linear infinite;
}

.shape-container--28 .random-shape:after {
  margin: 10.7rem;
  color: #e96b4a;
  font-size: 2.14rem;
  content: "";
}

@keyframes shape-29 {
  0% {
    transform: translate3d(0, 0, 0) rotate(162deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(522deg);
  }
}

.shape-container--29 {
  animation: shape-29 59s linear infinite;
}

.shape-container--29 .random-shape:after {
  margin: 6.7rem;
  color: #e96b4a;
  font-size: 1.34rem;
  content: "";
}

@keyframes shape-30 {
  0% {
    transform: translate3d(0, 0, 0) rotate(242deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(602deg);
  }
}

.shape-container--30 {
  animation: shape-30 58s linear infinite;
}

.shape-container--30 .random-shape:after {
  margin: 5.7rem;
  color: #e96b4a;
  font-size: 1.14rem;
  content: "";
}

@keyframes shape-31 {
  0% {
    transform: translate3d(0, 0, 0) rotate(144deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(504deg);
  }
}

.shape-container--31 {
  animation: shape-31 50s linear infinite;
}

.shape-container--31 .random-shape:after {
  margin: 5.7rem;
  display: none;
  color: #e96b4a;
  font-size: 1.14rem;
  content: "";
}

@keyframes shape-32 {
  0% {
    transform: translate3d(0, 0, 0) rotate(71deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(431deg);
  }
}

.shape-container--32 {
  animation: shape-32 49s linear infinite;
}

.shape-container--32 .random-shape:after {
  margin: 1.7rem;
  display: none;
  color: #e96b4a;
  font-size: 0.34rem;
  content: "";
}

@keyframes shape-33 {
  0% {
    transform: translate3d(0, 0, 0) rotate(116deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(476deg);
  }
}

.shape-container--33 {
  animation: shape-33 49s linear infinite;
}

.shape-container--33 .random-shape:after {
  margin: 6.7rem;
  color: #e96b4a;
  display: none;
  font-size: 1.34rem;
  content: "";
}

@keyframes shape-34 {
  0% {
    transform: translate3d(0, 0, 0) rotate(92deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(452deg);
  }
}

.shape-container--34 {
  animation: shape-34 64s linear infinite;
}

.shape-container--34 .random-shape:after {
  margin: 4.7rem;
  color: #e96b4a;
  font-size: 0.94rem;
  content: "";
}

@keyframes shape-35 {
  0% {
    transform: translate3d(0, 0, 0) rotate(13deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(373deg);
  }
}

.shape-container--35 {
  animation: shape-35 42s linear infinite;
}

.shape-container--35 .random-shape:after {
  margin: 10.7rem;
  color: #e96b4a;
  font-size: 2.14rem;
  content: "";
}

@keyframes shape-36 {
  0% {
    transform: translate3d(0, 0, 0) rotate(88deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(448deg);
  }
}

.shape-container--36 {
  animation: shape-36 44s linear infinite;
}

.shape-container--36 .random-shape:after {
  margin: 1.7rem;
  color: #e96b4a;
  font-size: 0.34rem;
  content: "";
}

@keyframes shape-37 {
  0% {
    transform: translate3d(0, 0, 0) rotate(250deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(610deg);
  }
}

.shape-container--37 {
  animation: shape-37 56s linear infinite;
}

.shape-container--37 .random-shape:after {
  margin: 2.7rem;
  color: #e96b4a;
  font-size: 0.54rem;
  content: "";
}

@keyframes shape-38 {
  0% {
    transform: translate3d(0, 0, 0) rotate(48deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(408deg);
  }
}

.shape-container--38 {
  animation: shape-38 59s linear infinite;
}

.shape-container--38 .random-shape:after {
  margin: 5.7rem;
  color: #e96b4a;
  font-size: 1.14rem;
  content: "";
}

@keyframes shape-39 {
  0% {
    transform: translate3d(0, 0, 0) rotate(206deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(566deg);
  }
}

.shape-container--39 {
  animation: shape-39 41s linear infinite;
}

.shape-container--39 .random-shape:after {
  margin: 5.7rem;
  color: #e96b4a;
  font-size: 1.14rem;
  display: none;
  content: "";
}

@keyframes shape-40 {
  0% {
    transform: translate3d(0, 0, 0) rotate(352deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(712deg);
  }
}

.shape-container--40 {
  animation: shape-40 47s linear infinite;
}

.shape-container--40 .random-shape:after {
  margin: 3.7rem;
  color: #e96b4a;
  font-size: 0.74rem;
  content: "";
}

@keyframes shape-41 {
  0% {
    transform: translate3d(0, 0, 0) rotate(37deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(397deg);
  }
}

.shape-container--41 {
  animation: shape-41 52s linear infinite;
}

.shape-container--41 .random-shape:after {
  margin: 9.7rem;
  color: #e96b4a;
  font-size: 1.94rem;
  content: "";
}

@keyframes shape-42 {
  0% {
    transform: translate3d(0, 0, 0) rotate(283deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(643deg);
  }
}

.shape-container--42 {
  animation: shape-42 56s linear infinite;
}

.shape-container--42 .random-shape:after {
  margin: 2.7rem;
  color: #ff94a1;
  font-size: 0.54rem;
  content: "";
}

@keyframes shape-43 {
  0% {
    transform: translate3d(0, 0, 0) rotate(310deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(670deg);
  }
}

.shape-container--43 {
  animation: shape-43 46s linear infinite;
}

.shape-container--43 .random-shape:after {
  margin: 10.7rem;
  color: #e96b4a;
  font-size: 2.14rem;
  content: "";
}

@keyframes shape-44 {
  0% {
    transform: translate3d(0, 0, 0) rotate(39deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(399deg);
  }
}

.shape-container--44 {
  animation: shape-44 53s linear infinite;
}

.shape-container--44 .random-shape:after {
  margin: 5.7rem;
  color: #e96b4a;
  font-size: 1.14rem;
  content: "";
}

@keyframes shape-45 {
  0% {
    transform: translate3d(0, 0, 0) rotate(129deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(489deg);
  }
}

.shape-container--45 {
  animation: shape-45 52s linear infinite;
}

.shape-container--45 .random-shape:after {
  margin: 4.7rem;
  display: none;
  color: #e96b4a;
  font-size: 0.94rem;
  content: "";
}

@keyframes shape-46 {
  0% {
    transform: translate3d(0, 0, 0) rotate(129deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(489deg);
  }
}

.shape-container--46 {
  animation: shape-46 48s linear infinite;
}

.shape-container--46 .random-shape:after {
  margin: 1.7rem;
  color: #e96b4a;
  font-size: 0.34rem;
  content: "";
}

@keyframes shape-47 {
  0% {
    transform: translate3d(0, 0, 0) rotate(317deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(677deg);
  }
}

.shape-container--47 {
  animation: shape-47 64s linear infinite;
}

.shape-container--47 .random-shape:after {
  margin: 5.7rem;
  color: #ff94a1;
  font-size: 1.14rem;
  content: "";
}

@keyframes shape-48 {
  0% {
    transform: translate3d(0, 0, 0) rotate(128deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(488deg);
  }
}

.shape-container--48 {
  animation: shape-48 61s linear infinite;
}

.shape-container--48 .random-shape:after {
  margin: 8.7rem;
  color: #ff94a1;
  font-size: 1.74rem;
  content: "";
}

@keyframes shape-49 {
  0% {
    transform: translate3d(0, 0, 0) rotate(201deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(561deg);
  }
}

.shape-container--49 {
  animation: shape-49 63s linear infinite;
}

.shape-container--49 .random-shape:after {
  margin: 8.7rem;
  color: #e96b4a;
  font-size: 1.74rem;
  content: "";
}

@keyframes shape-50 {
  0% {
    transform: translate3d(0, 0, 0) rotate(337deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(697deg);
  }
}

.shape-container--50 {
  animation: shape-50 62s linear infinite;
}

.shape-container--50 .random-shape:after {
  margin: 4.7rem;
  color: #ff94a1;
  font-size: 0.94rem;
  content: "";
}

@keyframes shape-51 {
  0% {
    transform: translate3d(0, 0, 0) rotate(318deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(678deg);
  }
}

.shape-container--51 {
  animation: shape-51 48s linear infinite;
}

.shape-container--51 .random-shape:after {
  margin: 3.7rem;
  color: #e96b4a;
  font-size: 0.74rem;
  content: "";
}

@keyframes shape-52 {
  0% {
    transform: translate3d(0, 0, 0) rotate(48deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(408deg);
  }
}

.shape-container--52 {
  animation: shape-52 42s linear infinite;
}

.shape-container--52 .random-shape:after {
  margin: 1.7rem;
  color: #e96b4a;
  font-size: 0.34rem;
  content: "";
}

@keyframes shape-53 {
  0% {
    transform: translate3d(0, 0, 0) rotate(141deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(501deg);
  }
}

.shape-container--53 {
  animation: shape-53 49s linear infinite;
}

.shape-container--53 .random-shape:after {
  margin: 8.7rem;
  color: #e96b4a;
  font-size: 1.74rem;
  content: "";
}

@keyframes shape-54 {
  0% {
    transform: translate3d(0, 0, 0) rotate(265deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(625deg);
  }
}

.shape-container--54 {
  animation: shape-54 60s linear infinite;
}

.shape-container--54 .random-shape:after {
  margin: 5.7rem;
  color: #e96b4a;
  font-size: 1.14rem;
  content: "";
}

@keyframes shape-55 {
  0% {
    transform: translate3d(0, 0, 0) rotate(178deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(538deg);
  }
}

.shape-container--55 {
  animation: shape-55 60s linear infinite;
}

.shape-container--55 .random-shape:after {
  margin: 9.7rem;
  color: #e96b4a;
  font-size: 1.94rem;
  content: "";
}

@keyframes shape-56 {
  0% {
    transform: translate3d(0, 0, 0) rotate(66deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(426deg);
  }
}

.shape-container--56 {
  animation: shape-56 67s linear infinite;
}

.shape-container--56 .random-shape:after {
  margin: 9.7rem;
  color: #e96b4a;
  font-size: 1.94rem;
  content: "";
}

@keyframes shape-57 {
  0% {
    transform: translate3d(0, 0, 0) rotate(49deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(409deg);
  }
}

.shape-container--57 {
  animation: shape-57 55s linear infinite;
}

.shape-container--57 .random-shape:after {
  margin: 10.7rem;
  color: #e96b4a;
  font-size: 2.14rem;
  content: "";
}

@keyframes shape-58 {
  0% {
    transform: translate3d(0, 0, 0) rotate(117deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(477deg);
  }
}

.shape-container--58 {
  animation: shape-58 62s linear infinite;
}

.shape-container--58 .random-shape:after {
  margin: 3.7rem;
  color: #e96b4a;
  font-size: 0.74rem;
  content: "";
}

@keyframes shape-59 {
  0% {
    transform: translate3d(0, 0, 0) rotate(183deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(543deg);
  }
}

.shape-container--59 {
  animation: shape-59 55s linear infinite;
}

.shape-container--59 .random-shape:after {
  margin: 9.7rem;
  color: #e96b4a;
  font-size: 1.94rem;
  content: "";
}

@keyframes shape-60 {
  0% {
    transform: translate3d(0, 0, 0) rotate(106deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(466deg);
  }
}

.shape-container--60 {
  animation: shape-60 47s linear infinite;
}

.shape-container--60 .random-shape:after {
  margin: 4.7rem;
  color: #ff94a1;
  font-size: 0.94rem;
  content: "";
}

@keyframes shape-61 {
  0% {
    transform: translate3d(0, 0, 0) rotate(176deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(536deg);
  }
}

.shape-container--61 {
  animation: shape-61 52s linear infinite;
}

.shape-container--61 .random-shape:after {
  margin: 1.7rem;
  color: #e96b4a;
  font-size: 0.34rem;
  content: "";
}

@keyframes shape-62 {
  0% {
    transform: translate3d(0, 0, 0) rotate(67deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(427deg);
  }
}

.shape-container--62 {
  animation: shape-62 63s linear infinite;
}

.shape-container--62 .random-shape:after {
  margin: 9.7rem;
  color: #e96b4a;
  font-size: 1.94rem;
  content: "";
}

@keyframes shape-63 {
  0% {
    transform: translate3d(0, 0, 0) rotate(123deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(483deg);
  }
}

.shape-container--63 {
  animation: shape-63 47s linear infinite;
}

.shape-container--63 .random-shape:after {
  margin: 10.7rem;
  color: #e96b4a;
  font-size: 2.14rem;
  content: "";
}

@keyframes shape-64 {
  0% {
    transform: translate3d(0, 0, 0) rotate(134deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(494deg);
  }
}

.shape-container--64 {
  animation: shape-64 50s linear infinite;
}

.shape-container--64 .random-shape:after {
  margin: 1.7rem;
  color: #ff94a1;
  font-size: 0.34rem;
  content: "";
}

@keyframes shape-65 {
  0% {
    transform: translate3d(0, 0, 0) rotate(322deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(682deg);
  }
}

.shape-container--65 {
  animation: shape-65 67s linear infinite;
}

.shape-container--65 .random-shape:after {
  margin: 6.7rem;
  color: #e96b4a;
  font-size: 1.34rem;
  content: "";
}

@keyframes shape-66 {
  0% {
    transform: translate3d(0, 0, 0) rotate(210deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(570deg);
  }
}

.shape-container--66 {
  animation: shape-66 68s linear infinite;
}

.shape-container--66 .random-shape:after {
  margin: 3.7rem;
  color: #e96b4a;
  font-size: 0.74rem;
  content: "";
}

@keyframes shape-67 {
  0% {
    transform: translate3d(0, 0, 0) rotate(339deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(699deg);
  }
}

.shape-container--67 {
  animation: shape-67 53s linear infinite;
}

.shape-container--67 .random-shape:after {
  margin: 7.7rem;
  color: #e96b4a;
  font-size: 1.54rem;
  content: "";
}

@keyframes shape-68 {
  0% {
    transform: translate3d(0, 0, 0) rotate(197deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(557deg);
  }
}

.shape-container--68 {
  animation: shape-68 46s linear infinite;
}

.shape-container--68 .random-shape:after {
  margin: 7.7rem;
  color: #e96b4a;
  font-size: 1.54rem;
  content: "";
}

@keyframes shape-69 {
  0% {
    transform: translate3d(0, 0, 0) rotate(141deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(501deg);
  }
}

.shape-container--69 {
  animation: shape-69 62s linear infinite;
}

.shape-container--69 .random-shape:after {
  margin: 7.7rem;
  color: #e96b4a;
  font-size: 1.54rem;
  content: "";
}

@keyframes shape-70 {
  0% {
    transform: translate3d(0, 0, 0) rotate(132deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(492deg);
  }
}

.shape-container--70 {
  animation: shape-70 50s linear infinite;
}

.shape-container--70 .random-shape:after {
  margin: 10.7rem;
  color: #e96b4a;
  font-size: 2.14rem;
  content: "";
}

@keyframes shape-71 {
  0% {
    transform: translate3d(0, 0, 0) rotate(162deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(522deg);
  }
}

.shape-container--71 {
  animation: shape-71 56s linear infinite;
}

.shape-container--71 .random-shape:after {
  margin: 9.7rem;
  color: #e96b4a;
  font-size: 1.94rem;
  content: "";
}

@keyframes shape-72 {
  0% {
    transform: translate3d(0, 0, 0) rotate(308deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(668deg);
  }
}

.shape-container--72 {
  animation: shape-72 60s linear infinite;
}

.shape-container--72 .random-shape:after {
  margin: 8.7rem;
  color: #e96b4a;
  font-size: 1.74rem;
  content: "";
}

@keyframes shape-73 {
  0% {
    transform: translate3d(0, 0, 0) rotate(345deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(705deg);
  }
}

.shape-container--73 {
  animation: shape-73 50s linear infinite;
}

.shape-container--73 .random-shape:after {
  margin: 5.7rem;
  color: #e96b4a;
  font-size: 1.14rem;
  content: "";
}

@keyframes shape-74 {
  0% {
    transform: translate3d(0, 0, 0) rotate(359deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(719deg);
  }
}

.shape-container--74 {
  animation: shape-74 48s linear infinite;
}

.shape-container--74 .random-shape:after {
  margin: 6.7rem;
  color: #e96b4a;
  font-size: 1.34rem;
  content: "";
}

@keyframes shape-75 {
  0% {
    transform: translate3d(0, 0, 0) rotate(255deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(615deg);
  }
}

.shape-container--75 {
  animation: shape-75 62s linear infinite;
}

.shape-container--75 .random-shape:after {
  margin: 10.7rem;
  color: #ff94a1;
  font-size: 2.14rem;
  content: "";
}

@keyframes shape-76 {
  0% {
    transform: translate3d(0, 0, 0) rotate(328deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(688deg);
  }
}

.shape-container--76 {
  animation: shape-76 49s linear infinite;
}

.shape-container--76 .random-shape:after {
  margin: 4.7rem;
  color: #ff94a1;
  font-size: 0.94rem;
  content: "";
}

@keyframes shape-77 {
  0% {
    transform: translate3d(0, 0, 0) rotate(242deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(602deg);
  }
}

.shape-container--77 {
  animation: shape-77 43s linear infinite;
}

.shape-container--77 .random-shape:after {
  margin: 6.7rem;
  color: #e96b4a;
  font-size: 1.34rem;
  content: "";
}

@keyframes shape-78 {
  0% {
    transform: translate3d(0, 0, 0) rotate(308deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(668deg);
  }
}

.shape-container--78 {
  animation: shape-78 65s linear infinite;
}

.shape-container--78 .random-shape:after {
  margin: 1.7rem;
  color: #e96b4a;
  font-size: 0.34rem;
  content: "";
}

@keyframes shape-79 {
  0% {
    transform: translate3d(0, 0, 0) rotate(155deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(515deg);
  }
}

.shape-container--79 {
  animation: shape-79 43s linear infinite;
}

.shape-container--79 .random-shape:after {
  margin: 10.7rem;
  color: #e96b4a;
  font-size: 2.14rem;
  content: "";
}

@keyframes shape-80 {
  0% {
    transform: translate3d(0, 0, 0) rotate(249deg);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(609deg);
  }
}

.shape-container--80 {
  animation: shape-80 66s linear infinite;
}

.shape-container--80 .random-shape:after {
  margin: 9.7rem;
  color: #ff94a1;
  font-size: 1.94rem;
  content: "";
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: rgba(255, 255, 255, 0);

  background-color: #ffffff00;
  border-color: #f5706e00;
  box-shadow: none;
}

.ellipse-small {
  -webkit-animation: pulse-animation 4s infinite;
  -webkit-animation-delay: 1.8s;
  animation: pulse-animation 4s infinite;
  animation-delay: 2.8s;
  transform: scale(0);
}

@keyframes pulse-animation {
  0% {
    transform: scale(0);
  }

  60% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
    transform: scale(1.4);
  }
}

.ellipse-large {
  -webkit-animation: pulse-animation1 4s infinite;
  -webkit-animation-delay: 1s;
  animation: pulse-animation1 4s infinite;
  animation-delay: 1s;
  transform: scale(0);
}

@keyframes pulse-animation1 {
  0% {
    transform: scale(0);
  }

  60% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@font-face {
  font-family: "unineue";
  src: url("../static/fonts/UniNeueHeavy.otf");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "nunito";
  src: url("../static/fonts/Nunito-SemiBold.woff");
}

@font-face {
  font-family: "nunito-regular";
  src: url("../static/fonts/Nunito-Regular.woff");
}

.discover-button:hover {
  background-color: rgb(233, 107, 74) !important;
  color: white !important;
}

.ant-input::placeholder {
  color: rgba(255, 255, 255, 0.753);
}

.lightbox {
  position: fixed;
  z-index: 999999999999 !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.849);
}

.box {
  border-radius: 1em;
  z-index: 9999;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.308) !important;
  position: absolute;
  padding: 50px;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(233, 107, 74);
  color: #fff;
}

.card1,
.card2,
.card3,
.card4 {
  position: absolute;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform;
}

.card1 {
  min-width: 60ch;
  min-height: 60ch;
  width: 45vw;
  height: 45vw;
  max-width: 100ch;
  max-height: 100ch;
  background-image: url(https://image.flaticon.com/icons/svg/119/119596.svg);
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-button-1:hover {
  color: rgb(233, 107, 74) !important;
  border: 1px solid rgb(233, 107, 74) !important;
  background-color: white !important;
}

.home-button:hover {
  transition: 0.3s;

  border: 0px solid #ffffff !important;
}

a.button-l-2 {
  right: 5%;
  top: -4em;
  position: absolute;
  z-index: 1;
  width: 8em;
  height: 8em;
  overflow: hidden;
  vertical-align: middle;
  text-align: left;
  text-decoration: none;
  line-height: 3em;
  font-size: 1rem;
  color: rgb(233, 107, 74);
  cursor: pointer;
  -webkit-transform: translateZ(0);
  transform: translateZ(0) rotate(180deg) scale(0.8);
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

a.button-l-2 svg {
  height: 20vh;
  width: 100vw;
  z-index: 1;
  position: absolute;
  left: -5em;
}

a.button-l-2 svg g line,
a.button-l-2 svg g polyline,
a.button-l-2 svg g circle {
  fill: none;
  stroke: rgb(233, 107, 74);
  stroke-width: 0.1em;
}

a.button-l-2 svg g line,
a.button-l-2 svg g polyline {
  height: 10vh;
  width: 10vw;
  stroke-miterlimit: 10;
}

a.button-l-2 svg g circle {
  display: block;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 0.1em;
  stroke-dasharray: 200;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset ease-out 0.5s;
}

font {
  right: 5.6%;
  top: 0em;
  font-stretch: condensed;
  font-family: "nunito-regular";
  color: #110f0f;
  text-align: left;
  z-index: 2;
  position: absolute;
  display: inline-block;
  font-size: 18px;
}

a.button-l-2:hover {
  -webkit-transform: translateZ(0) translateX(-1em) scale(0.8);
  transform: translateZ(0) translateX(-1em) rotate(180deg) scale(0.8);
}

a.button-l-2:hover svg g circle {
  stroke-dashoffset: -200;
}

@font-face {
  font-family: "Chenla";
  src: url("../static/fonts/Chenla.ttf");

  font-weight: normal;
  font-style: normal;
}

.ant-slider-mark-text {
  color: rgb(255, 255, 255);
}

.home-image-audio {
  position: absolute;
  right: -0em;
  height: calc(105vh);
  min-height: 830px;
  margin-right: -1.2em;
  top: 0;
  max-width: 80em;
  display: inline;
}

.home-image-faq {
  position: absolute;
  left: -0em;
  min-height: 830px;
  margin-right: -1.2em;
  top: 0;
  max-height: 159em;
  display: inline;
}

.home-image {
  position: absolute;
  right: -0em;
  height: calc(100vh);
  min-height: 830px;
  margin-right: -1.2em;
  top: 0;
  max-width: 80em;
  display: inline;
}

.home-image-mobile {
  display: none;
}

.burger-menu {
  display: none;
}

.ant-drawer-title {
  font-size: 28px !important;
  position: relative;
  color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(255, 255, 255);
  padding-bottom: 1em;
}

.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100vh;
}

.ant-drawer-header {
  font-size: 25px;
  margin: auto;
  margin-top: 1em;
  position: relative;
  padding: 16px 30px;

  background: #00d32700;

  border-bottom: 0px solid #3a8ea8;
  border-radius: 3em;
}

.ant-drawer-content {
  background-color: rgb(233, 107, 74);
}

.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: #fff;
  border: 2px solid #b8b8b8 !important;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #b8b8b8 !important;
  font-weight: 700;
  padding-left: 1em;
}

.ant-drawer-close {
  position: absolute;
  font-size: 28px;
  top: -0.8em;
  transform: scale(0.6);
  border-radius: 5em;
  background-color: rgba(255, 255, 255, 0.13);
  color: #ffffff;
  right: 0em;
}

.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: #ffffff18;
}

.home-back-mobile-1 {
  display: none;
}

.home-back-mobile {
  display: none;
}

.cardHeader {
  transition: all 500ms ease-in-out;
}

.cardImg {
  transition: all 500ms ease-in-out;
}

.cardItem {
  transition: all 500ms ease-in-out;
}

.what-we-boxes:hover .cardItem {
  transform: scale(1.05) !important;

  cursor: pointer;

  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}

.what-we-boxes:hover .cardHeader {}

.what-we-boxes:hover .cardImg {
  filter: drop-shadow(8px 4px 3px #3d3d3d54);
  transform: skewY(3deg) scale(1.1);
  cursor: default;
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}

.footer-image {
  margin-top: -15em;
  display: inline;
  height: 61em;
  min-width: 110em !important;
  min-height: 54.5em !important;
}

.footer-image-mobile {
  display: none;
}

.header {
  background: rgba(255, 255, 255, 0) !important;

  -webkit-transition: all ease-out 0.5s;
  -moz-transition: all ease-out 0.5s;
  -o-transition: all ease-out 0.5s;
  transition: all ease-out 0.5s;
}

.header-links-style {
  transition: 0.3s;
}

.header-links-style:hover {
  transition: 0.3s;
  border-radius: 1em;
  margin-left: -0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border: 1px solid rgb(233, 107, 74);
  color: rgb(233, 107, 74) !important;
}

.ant-modal-content {
  background-color: rgba(255, 255, 255, 0) !important;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0) !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0) !important;
}

.ant-modal-close {
  margin-right: 2em !important;
  margin-top: 2em !important;
  color: rgb(255, 255, 255) !important;
  background: rgba(233, 106, 74, 0.548) !important;
  border-radius: 10em !important;
}

.ant-modal-title {
  color: rgba(255, 255, 255, 1) !important;
  font-size: 20px !important;
}

.ant-modal-header {
  color: rgb(255, 255, 255) !important;
  background: rgba(255, 255, 255, 0) !important;
  border-bottom: 0px solid #e8e8e8 !important;
}

.ant-modal {
  margin-top: -5em !important;
  width: 70vw !important;
}

.ant-modal-footer {
  display: none !important;
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 10px;
  right: 14px;
  border: none !important;
  border-radius: 3em;
  padding: 0;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.849);
  display: flex;
}

.react-responsive-modal-modal {
  text-align: center;
  border-radius: 1em;
  max-width: 700px !important;
  min-width: 300px !important;

  position: relative;
  padding: 2rem !important;
  background: white;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: auto;
}

.dots-left {
  display: none;
}

.dots-right {
  display: inline;
}

.service-image-small {
  display: none;
}

.dots-left2 {
  display: none;
}

.dots-left-idea {
  margin: auto !important;
  text-align: center !important;
  margin-left: 13.5em !important;
}

.dots-left-idea-mobile {
  display: none;
}

.service-row {
  min-width: 70em !important;
}

.dots-left-idea1 {
  display: none;
}

.home-button-4 {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .home-image {
    position: absolute;
    right: -0em;
    height: calc(100vh);
    min-height: 830px;
    margin-right: -7em;
    top: 0;
    max-width: 80em;
    display: inline;
  }
}

@media only screen and (max-width: 770px) {
  .blogs-row {
    height: 86em !important;
  }

  .main-blogs-header {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }

  .ant-modal {
    max-width: 100vw !important;
    margin: 0em !important;
    margin-top: -5em !important;
    width: 100vw !important;
  }

  .home-image-faq {
    display: none;
  }

  .home-image {
    position: absolute;
    right: -0em;
    height: calc(100vh);
    min-height: 830px;
    margin-right: -1.2em;
    top: 0;
    max-width: 80em;
    display: inline;
  }

  .big-wave-image {
    display: none;
    margin-top: -10em !important;
    margin-left: -10em;
    min-width: 172em !important;
  }

  .sign-up-section {
    border-radius: 1em;
    padding-top: 2em;
    padding-bottom: 2em;
    background-color: rgb(233, 107, 74);
    margin-top: 2em !important;
    margin-bottom: -10em !important;
  }

  .chatbot {
    display: none;
  }

  .home-button-1 {
    margin-top: 10vh !important;
  }

  .home-button-4 {
    display: inline;
  }

  .home-button-3 {
    margin-top: 5vh !important;
  }

  .send-message-button {
    text-align: center !important;
    min-width: 85vw !important;
    margin-right: 3.8em !important;
  }

  .contact-hidden {
    display: none;
  }

  .dots-left-idea-mobile {
    display: inline;
    margin: auto !important;
    text-align: center !important;
    margin-left: 4em !important;
  }

  .dots-left-idea {
    display: none;
  }

  .capsule-image {
    display: none;
  }

  .moon-image {
    width: 7em;
  }

  .dots-left-idea {
    margin: auto !important;
    text-align: center !important;
    margin-left: 12em !important;

    margin-top: 0.7em !important;
  }

  .dots-left-idea1 {
    display: inline;
    margin: auto !important;
    text-align: center !important;
    margin-left: 12em !important;
    margin-top: 0.7em !important;
  }

  .dots-left2 {
    margin: auto !important;
    text-align: center !important;
    margin-left: 12em !important;
  }

  .dots-left1 {
    display: none;
  }

  .dots-left {
    position: absolute;
    transform: rotate(180deg);
    margin: auto !important;

    text-align: center !important;
    margin-left: -50em !important;

    display: inline;
  }

  .dots-right {
    display: none;
  }

  .services-button {
    margin-top: -1em !important;
  }

  .ant-slider-step {
    height: 4px;

    background: #ffffff;
  }

  .input-budget-text {
    font-size: 15px;

    margin-left: 0em;
  }

  .ant-slider {
    width: 75vw;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.65);
    font-size: 19px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: "tnum", "tnum";
    font-feature-settings: "tnum", "tnum";
    position: relative;
    height: 12px;
    margin: 0px 0px 20px;
    margin-left: 0em;

    padding: 4px 0;
    cursor: pointer;
    -ms-touch-action: none;
    touch-action: none;
  }

  .footer-burn {
    bottom: -8em !important;
  }

  .header {
    width: 100vw !important;
    z-index: 0 !important;
    display: inline;
    position: absolute !important;
    margin-bottom: -4em !important;
    padding-bottom: 0em !important;
  }

  .service-image-small {
    display: inline;
  }

  .burger-menu {
    background-color: transparent;
    display: inline-block;
  }

  .header-links {
    display: none;
  }

  .logo-image {
    z-index: 1000;
    visibility: visible;
    position: absolute;
    width: 18em !important;
    top: -1.1em;
    margin-left: -0.8em !important;
  }

  .logo-image-col {
    position: absolute;
  }

  .home-header {
    margin-top: 0em !important;

    font-size: 55px !important;
    text-align: center;
  }

  .what-we-do {
    margin-top: 5em !important;
    font-size: 40px !important;
    text-align: center;
  }

  .home-button {
    margin: auto !important;
    text-align: center !important;
  }

  .home-row {
    top: 20vh !important;
    margin: auto !important;
    text-align: center !important;
  }

  .survey-img {
    display: none;
  }

  .survey-paragraph {
    text-align: center;
    padding-top: 5em !important;
  }

  .blog-cards {
    text-align: center !important;
    margin-top: 0em;
  }

  .blogs-cards {
    text-align: center !important;
    margin-top: 0em !important;
  }

  .survey-section {
    min-width: auto !important;
  }

  .sell-cards {
    width: 95% !important;
  }

  .sell-cards-full-assessment {
    min-height: 34em !important;
    width: 95% !important;
  }

  .home-image-audio {
    display: none;
  }

  .home-image {
    display: none;
  }

  .home-image-mobile-home {
    display: none;
  }

  .home-image-mobile {
    border-radius: 1em !important;
    width: 96vw !important;
    min-width: 8em;
    margin-left: -0.2em;
    margin-top: 2em;
    margin-bottom: 1em;
    display: inline;
  }

  .active.header {
    display: none;
  }

  .home-div {
    padding: 10px !important;
    max-height: 20em !important;
  }

  .home-back {
    display: none;
  }

  .home-back-mobile {
    display: inline;
    overflow: hidden;

    min-height: 50em !important;
    max-height: 56em !important;
  }

  .home-back-mobile-1 {
    display: inline;
    overflow: hidden;

    min-height: 85em !important;
  }

  .display-inline {
    text-align: center;
    display: inline !important;
  }

  .header-number {
    font-size: 30px !important;
    margin-left: 2em !important;
  }

  .header-number2 {
    font-size: 35px !important;
    margin-left: -1em !important;
    margin-top: -0.25em !important;
  }

  .header-number3 {
    font-size: 30px !important;
    margin-left: 1.6em !important;
    margin-top: -0.25em !important;
  }

  .center {
    margin: auto !important;
    text-align: center !important;
  }

  .center-header-why {
    max-width: 7.5em;
    font-size: 40px !important;
    margin: auto !important;
    margin-top: 2em !important;
    text-align: center !important;
  }

  .center-subheader-deep {
    width: 100%;
    font-size: 25px !important;
    margin: auto !important;
    margin-top: -14em !important;
    text-align: center !important;
  }

  .center-subheader-rapid {
    width: 100%;
    font-size: 25px !important;
    margin: auto !important;
    margin-top: 11.7em !important;
    text-align: center !important;
  }

  .center-subheader-competititve {
    width: 100%;
    font-size: 25px !important;
    margin: auto !important;
    margin-top: 2em !important;
    text-align: center !important;
  }

  .center-subheader {
    width: 100%;
    font-size: 25px !important;
    margin: auto !important;
    margin-top: 2em !important;
    text-align: center !important;
  }

  .center-sub-paragraph {
    line-height: 1.2em !important;
    font-size: 16px !important;
    margin: auto !important;
    text-align: center !important;
    margin-top: 1em !important;
  }

  .rates-image {
    text-align: center;
    margin: auto;
    max-width: 16em !important;
    margin-top: 15em !important;
  }

  .satelite-image {
    text-align: center;
    margin: auto;
    max-width: 16em !important;
    margin-top: 3.5em !important;
  }

  .space-image {
    text-align: center;
    margin: auto;
    max-width: 14em !important;
    margin-top: 16em !important;
  }

  .rapid-create {
    text-align: center;
    margin: auto;
    min-width: 8em !important;
    margin-top: 3.5em;
  }

  .expert-image {
    text-align: center;
    margin: auto;
    min-width: 15em !important;
    margin-top: 12em;
    margin-left: -2em;
  }

  .address-bar {
    display: none;
  }

  .footer-image {
    display: none;
  }

  .service-row {
    min-width: auto !important;
    margin-top: 3em !important;
  }

  .left-header1 {
    width: 100%;
    margin-bottom: 0em !important;
    margin-left: 0em !important;
    text-align: center !important;
  }

  .left-header-launch {
    width: 100%;
    margin-bottom: 0.5em !important;
    margin-left: -0.5em !important;
    text-align: center !important;
  }

  .left-paragraph1 {
    padding-right: 1em;
    margin-left: 1.3em !important;
    text-align: center !important;
  }

  .left-paragraph2 {
    margin-top: 0.6em !important;
    padding-right: 1em;
    margin-left: 0em !important;
    text-align: center !important;
  }

  .left-header {
    text-align: center !important;
    margin-bottom: -0.5em !important;
    margin-left: -1.5em;
  }

  .left-header-quality {
    text-align: center !important;
    margin-bottom: -0.5em !important;
    margin-left: -3.5em;
  }

  .left-paragraph-ui {
    padding-left: 0em !important;
    text-align: center !important;
    padding-right: 1em;
    width: 80%;
    margin-top: 1.7em !important;
    margin-left: 4.9em;
  }

  .left-paragraph-quality {
    padding-left: 0em !important;
    text-align: center !important;
    padding-right: 1em;
    width: 80%;
    margin-top: 1.7em !important;
    margin-left: 3em;
  }

  .left-paragraph-maintenance {
    padding-left: 0em !important;
    text-align: center !important;
    padding-right: 1em;
    width: 80%;
    margin-top: 1.7em !important;
    margin-left: 5.3em;
  }

  .service-image {
    display: none;
  }

  .footer-image-mobile {
    display: inline;
    height: 97em;
    margin-top: -50em !important;
    min-height: 48.5em !important;
  }

  .contact-row {
    margin-top: 12.5em !important;
    display: block !important;
  }

  .center-subheader-expert {
    width: 100%;
    font-size: 25px !important;
    margin: auto !important;
    margin-top: -12.5em !important;
    text-align: center !important;
  }

  .center-subheader-agile {
    width: 100%;
    font-size: 25px !important;
    margin: auto !important;
    margin-top: -14em !important;
    text-align: center !important;
  }

  .center-header-audio {
    padding-top: 0.5em;
    font-size: 40px !important;
    margin: auto !important;

    text-align: center !important;
  }

  .center-header {
    font-size: 40px !important;
    margin: auto !important;

    text-align: center !important;
  }

  .center-paragraph {
    padding: 0.5em !important;

    font-size: 18px !important;
    margin: auto !important;
    text-align: center !important;
  }

  .center-header-contact {
    color: black !important;
    white-space: nowrap;
    font-size: 40px !important;
    margin: auto !important;
    margin-left: -0em !important;
    margin-bottom: 0.5em !important;

    text-align: center !important;
  }

  .contact-col {
    margin-top: 3em !important;
  }

  .contact-div {
    width: 100vw;
    margin: auto !important;
    margin-left: -0.6em !important;
    text-align: center !important;
  }

  .pragraph-icons {
    display: none;
  }

  .center-paragraph-why1 {
    color: #110f0fd3 !important;

    font-size: 20px !important;
    margin: auto !important;
    margin-left: -0em !important;

    margin-bottom: 4em !important;
    text-align: center !important;
  }

  .center-paragraph-why {
    font-size: 20px !important;
    margin: auto !important;
    margin-top: 1em !important;

    text-align: center !important;
  }

  .what-we-boxes {
    margin-top: 2em;
  }

  .process-img-row {
    margin-bottom: 0em !important;
  }

  .form-item-style {
    margin-left: auto !important;
    width: 77vw;
  }

  .form-item-style1 {
    margin-left: auto !important;
    width: 77vw;
  }

  .form-item-style-number {
    margin-left: auto !important;

    width: 77vw;
  }

  .ellipse-small {
    left: -13em !important;
    -webkit-animation: pulse-animation 4s infinite;
    -webkit-animation-delay: 1.8s;
    animation: pulse-animation 4s infinite;
    animation-delay: 2.8s;
    transform: scale(0);
  }

  @keyframes pulse-animation {
    0% {
      transform: scale(0);
    }

    60% {
      opacity: 0.2;
    }

    100% {
      opacity: 0;
      transform: scale(0.5);
    }
  }

  .ellipse-large {
    left: -13em !important;
    -webkit-animation: pulse-animation1 4s infinite;
    -webkit-animation-delay: 1s;
    animation: pulse-animation1 4s infinite;
    animation-delay: 1s;
    transform: scale(0);
  }

  @keyframes pulse-animation1 {
    0% {
      transform: scale(0);
    }

    60% {
      opacity: 0.2;
    }

    100% {
      opacity: 0;
      transform: scale(0.5);
    }
  }

  .process-button {
    left: -2.5em !important;
    top: 60em !important;
    width: 14em !important;
  }

  .process-back {
    bottom: 0;
    min-height: 0em !important;
  }

  .our-process-header {
    font-weight: 500 !important;
    color: black !important;
    margin-top: 0.9em !important;
    line-height: 34px !important;
    font-size: 35px !important;
    width: auto !important;
    max-width: 16em;
  }

  .our-process-paragraph {
    padding-left: 2em !important;
    padding-right: 2em !important;
    margin-bottom: -0.5em;
    font-size: 18px !important;
  }

  .process-pic-mobile {
    transform: scale(1.1);
    top: -1em !important;
    right: 0em;
    left: -2.6em;

    display: inline !important;
  }

  .process-pic {
    display: none;
  }

  .process-header {
    visibility: hidden;
  }

  .life-cycle-line {
    margin-left: 2% !important;

    margin-top: -5em !important;
    transform: scale(0.85);
  }

  .process-paragraph {
    margin-left: 1.5em;
    text-align: center !important;
  }

  .services-div {
    margin-left: 0em !important;
  }

  .concept-paragraph {
    top: -1em !important;
    left: -2em !important;
  }

  .design-paragraph {
    top: 20em !important;
    left: -27em !important;
  }

  .testing-paragraph {
    top: 43em !important;
    left: -27em !important;
  }

  .interaction-paragraph {
    top: 4.5em !important;
    left: -3em !important;
  }

  .development-paragraph {
    top: 27em !important;
    left: -27em !important;
  }

  .launch-paragraph {
    top: 50em !important;
    left: -27em !important;
  }

  .lightbox {
    display: none !important;
  }

  .logo-image-green {
    position: relative !important;
    margin-top: 1.8em !important;
    width: 4em !important;
  }

  .mail-item-margin-style {
    margin-top: 0.1em !important;
  }

  a.button-l-2 {
    display: none;
  }

  font {
    display: none;
  }
}

@media only screen and (max-width: 350px) {
  .process-header {
    visibility: visible;
  }

  .process-pic-mobile {
    visibility: hidden;
  }

  .process-paragraph {
    margin-left: 0em;
    text-align: center !important;
  }

  .services-button {
    margin-top: -3em !important;
  }
}